<template>
  <div class="ship-legend" 
       :style="{ left: position.x + 'px', top: position.y + 'px' }"
       @mousedown="startDrag"
       ref="legend">
    <div class="legend-header">
      <span>船舶图例</span>
      <i class="el-icon-close" @click="close"></i>
    </div>
    <div class="legend-content">
      <div class="legend-item" v-for="(item, index) in shipLegendItems" :key="index">
        <svg class="legend-icon" viewBox="0 0 1792 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <path d="M1505.28 512l-1216 320V192l1216 320z" :fill="item.color" p-id="2955"></path>
          <path d="M262.4 865.28V158.72L1605.12 512 262.4 865.28z m51.2-640v573.44L1404.16 512 313.6 225.28z" fill="#e6e6e6" p-id="2956"></path>
        </svg>
        <span class="legend-text">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipLegend',
  data() {
    return {
      position: {
        x: window.innerWidth - 200,  // 窗口宽度减去图例宽度和边距
        y: 70
      },
      isDragging: false,
      dragOffset: {
        x: 0,
        y: 0
      }
    }
  },
  computed: {
    shipLegendItems() {
      return [
        { color: "#5470c6", name: "未知" },
        // { color: "#91cc75", name: "海警船" },
        { color: "#fac858", name: "作业船" },
        { color: "#ee6666", name: "特种船" },
        { color: "#73c0de", name: "客船" },
        { color: "#3ba272", name: "货船" },
        { color: "#fc8452", name: "油船" },
       
        { color: "#fafab0", name: "渔船" },
        { color: "#9a60b4", name: "其他" },
        
      ];
    }
  },
  mounted() {
    window.addEventListener('resize', this.updatePosition);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updatePosition);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    startDrag(event) {
      if (event.target.closest('.legend-header')) {
        this.isDragging = true;
        const rect = this.$refs.legend.getBoundingClientRect();
        this.dragOffset = {
          x: event.clientX - rect.left,
          y: event.clientY - rect.top
        };
        document.addEventListener('mousemove', this.doDrag);
        document.addEventListener('mouseup', this.stopDrag);
      }
    },
    doDrag(event) {
      if (this.isDragging) {
        this.position = {
          x: event.clientX - this.dragOffset.x,
          y: event.clientY - this.dragOffset.y
        };
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.doDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    updatePosition() {
      this.position = {
        x: Math.min(window.innerWidth - 200, this.position.x),
        y: this.position.y
      };
    }
  }
}
</script>

<style scoped>
.ship-legend {
  position: fixed;
  width: 180px;
  background-color: rgba(0, 0, 0, 0.7);  /* 半透明黑色背景 */
  border-radius: 8px;  /* 增加圆角 */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(255, 255, 255, 0.1);  /* 增强阴影效果，添加细边框 */
  z-index: 1000;
  user-select: none;
  backdrop-filter: blur(5px);  /* 背景模糊效果 */
}

.legend-header {
  cursor: move;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);  /* 半透明白色分隔线 */
  color: #ffffff;  /* 白色文字 */
  font-weight: bold;
}

.legend-header i {
  cursor: pointer;
  color: #ffffff;  /* 白色图标 */
}

.legend-content {
  padding: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.legend-icon {
  margin-right: 10px;
}

.legend-text {
  font-size: 14px;
  color: #ffffff;  /* 白色文字 */
}
</style>